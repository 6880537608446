import './App.css';
import './index.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import AppRouter from './routes/Routes';

function App() {
  return (
    <>
      <AppRouter/>
    </>
  );
}

export default App;
