import React from 'react';
import { Container } from '@mui/material';

export default function AboutUs() {
    return (
        <>
            <Container maxWidth="xl">
                
            </Container>
        </>
    )
};